import React from "react";

import Layout from "../layout/layout";
import HostingProcess from "../components/services/hosting-process";

const Hosting = () => {

  return (
    <Layout>
      <HostingProcess />
    </Layout>
  );
};

export default Hosting;
